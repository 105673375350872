<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'

export default {
  name: 'institutionsDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        sort: 1,
        treeLevel: 1
      },
      isAddData: 1
    }
  },
  mounted() {
    const { id, isAddData } = this.$route.query
    if (isAddData) {
      this.isAddData = isAddData
    }
    id &&
      api.command.getDetail
        .call(this, {
          url: `/dept/${id}`
        })
        .then(result => {
          console.log(result)
        })
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '机构名称',
            type: 'input',
            cols: 12,
            key: 'deptName',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '机构负责人',
            type: 'input',
            cols: 12,
            key: 'leader',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '层次级别',
            type: 'inputNumber',
            cols: 12,
            key: 'treeLevel'
          },
          {
            name: '排序号',
            type: 'inputNumber',
            cols: 12,
            key: 'sort'
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            const { parentCode } = this.$route.query
            let params = {}
            if (this.isAddData != 3) {
              params = {
                ...data,
                treeSort: '1',
                treeLeaf: '0',
                parentCode: parentCode || '0'
              }
            } else {
              params = data
            }
            api.command[this.isAddData != 3 ? 'create' : 'edit']
              .call(this, {
                url: '/dept',
                params: params,
                isPost: false
              })
              .then(result => {
                this.$router.push({
                  path: '/infoMain/institutions'
                })
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/infoMain/institutions')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1()]} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
